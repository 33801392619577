<template>
  <div class="lesson__wrapper" @click="editItemHandler">
    <div class="lesson__index inline">#{{ index + 1 }}</div>
    <div class="lesson__date inline">
      <div class="lesson__date__date">
        {{ $moment(date).format('YYYY-MM-DD HH:mm') }}
      </div>
      <div class="lesson__date__weekday">
        {{ $moment(date).format('dddd') }}
      </div>
      <div class="lesson__date-repeat" v-if="repeat">
        <div class="lesson__date-repeat__freq">
          Repeat: <strong>{{ freq }}</strong
          >, every <strong>{{ interval }}</strong>
        </div>
        <div class="lesson__date-repeat__until">
          Until: <strong>{{ $moment(until).format('YYYY-MM-DD') }}</strong>
        </div>
        <div class="lesson__date-repeat__count" v-if="count">
          All count: {{ count }}
        </div>
      </div>
    </div>
    <div class="lesson__info">
      <div class="lesson__room inline" v-if="room">
        <vs-icon :icon="room.icon"></vs-icon>
        <span>{{ room.name }}</span>
      </div>
      <div class="lesson__teacher inline" v-if="teacher">
        <vs-chip transparent>
          <vs-avatar
            :src="
              teacher.options && teacher.options.avatar
                ? `${proxy}/static/files/${teacher.options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ teacher.nickname }}
        </vs-chip>
      </div>
      <div class="lesson__teacher sub inline" v-if="subTeachers.length">
        <span>Sub teachers:</span>
        <span v-for="(teacher, index) in subTeachers" :key="index">
          <vs-chip transparent>
            <vs-avatar
              size="16px"
              :src="
                teacher.options && teacher.options.avatar
                  ? `${proxy}/static/files/${teacher.options.avatar}`
                  : undefined
              "
            ></vs-avatar>
            {{ teacher.nickname }}
          </vs-chip>
        </span>
      </div>
      <div class="lesson__teacher sub inline" v-if="assistants.length">
        <span>Assistants:</span>
        <span v-for="(teacher, index) in assistants" :key="index">
          <vs-chip transparent>
            <vs-avatar
              size="16px"
              :src="
                teacher.options && teacher.options.avatar
                  ? `${proxy}/static/files/${teacher.options.avatar}`
                  : undefined
              "
            ></vs-avatar>
            {{ teacher.nickname }}
          </vs-chip>
        </span>
      </div>
    </div>

    <div class="lesson__price inline">Price: {{ price || '-' }}р.</div>
    <div class="actions">
      <vs-button
        type="flat"
        icon="edit"
        color="dark"
        size="small"
        @click="editItemHandler"
      >
      </vs-button>
      <vs-button
        type="flat"
        icon="delete"
        color="danger"
        size="small"
        @click="deleteItemHandler($event)"
        title="Delete lesson"
        >Delete</vs-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'GroupFormLessonItem',
  props: ['index', 'lesson', 'schoolyear_id'],
  data() {
    let data = {
      proxy: process.env.VUE_APP_PROXY,
    }

    return data
  },
  computed: {
    ...mapState({
      teachersList: state => state.teachers.teachers,
      rooms: state => state.rooms.rooms,
      years: state => state.years.years,
    }),
    until: function() {
      if (this.oldVersion) {
        const schoolyear = this.years.find(y => y.id == this.schoolyear_id)
        return schoolyear
          ? new Date(schoolyear.date_end_unix * 1000)
          : new Date()
      } else {
        return this.lesson.until
          ? new Date(this.lesson.until * 1000)
          : new Date()
      }
    },
    teacher: function() {
      return this.teachersList.find(t => t.id == this.lesson.teacher)
    },
    subTeachers: function() {
      if (typeof this.lesson.other_teachers == 'undefined') return []
      return this.lesson.other_teachers.map(stId => {
        const teacher = this.teachersList.find(t => t.id == stId)
        if (!teacher) return
        const { nickname, options } = teacher
        return {
          nickname,
          options,
        }
      })
    },
    assistants: function() {
      if (!this.lesson.assistants) return []
      return this.lesson.assistants.map(stId => {
        const teacher = this.teachersList.find(t => t.id == stId)
        if (!teacher) return
        const { nickname, options } = teacher
        return {
          nickname,
          options,
        }
      })
    },
    room: function() {
      return this.rooms.find(r => r.id == this.lesson.room)
    },
    schoolyear: function() {
      return this.years.find(y => y.id == this.schoolyear_id)
    },
    date: function() {
      if (this.oldVersion) {
        const { day, time } = this.lesson
        const date = new Date()
        date.setDate(date.getDate() + (day - date.getDay() + 1))

        const [hour, minutes] = time.split(':')
        date.setHours(parseInt(hour, 10))
        date.setMinutes(parseInt(minutes, 10))
        date.setSeconds(0)
        date.setMilliseconds(0)
        return date
      }
      return this.lesson.date ? new Date(this.lesson.date * 1000) : null
    },
    price: function() {
      return this.lesson.price || null
    },
    freq: function() {
      return this.oldVersion ? 'weekly' : this.lesson.freq
    },
    interval: function() {
      return this.oldVersion || !this.lesson.interval ? 1 : this.lesson.interval
    },
    count: function() {
      return this.oldVersion || !this.lesson.count ? null : this.lesson.count
    },
    repeat: function() {
      return this.oldVersion || typeof this.lesson.repeat === 'undefined'
        ? true
        : this.lesson.repeat
    },
    oldVersionDate: function() {
      return new Date()
    },
    fromOldParams: function() {
      const data = {
        date: this.oldVersionDate,
        freq: 'weekly',
        interval: 1,
        until: new Date(this.schoolyear.date_end_unix * 1000),
        repeat: true,
      }
      return data
    },
  },
  methods: {
    editItemHandler: function() {
      this.$emit('editGroupLesson', this.index, this.date / 1000)
    },
    deleteItemHandler: function(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete lesson',
        text: 'Are you sure you want to delete this lesson?',
        accept: this.deleteLessonCallback,
      })
    },
    deleteLessonCallback: function() {
      this.$emit('deleteGroupLesson', this.index)
    },
  },
  beforeMount: function() {
    this.oldVersion = typeof this.lesson.date === 'undefined' ? true : false
  },
}
</script>

<style lang="scss">
.lesson__wrapper {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin: 15px 0;
  font-size: 0.9em;
  align-items: start;
  transition: all 0.3s;
  & > * {
    margin: 0 5px;
    flex: 0.5;
  }
  &:hover {
    box-shadow: 0 0 5px 1px #ccc;
  }
  .lesson__index {
    font-weight: bold;
    flex: 0;
  }
  .lesson__date {
    width: 30%;
    flex: 1;
    .lesson__date__date {
      font-size: 1.1em;
      font-weight: bold;
    }
  }

  // .lesson__info {
  //   .con-vs-chip {
  //     float: none;
  //   }
  // }
  .lesson__info {
    flex: 1;
    display: flex;
    flex-flow: column;
    .con-vs-chip {
      float: left;
      margin: 0;
    }
    .lesson__room {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  .lesson__date__weekday {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .lesson__teacher {
    &.sub {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.2rem;
    }
  }
}
</style>
