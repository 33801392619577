<template>
  <div class="lesson-form popup-form">
    <div class="form-content-two-parts">
      <div class="inline-part left">
        <v-date-picker
          v-model="date"
          mode="datetime"
          is24hr
          :minute-increment="15"
          class="time-picker"
        />
        <div class="field" v-if="lessonType == 'tutor'">
          <vs-input
            size="large"
            label="Lesson duration in minutes"
            placeholder="Lesson duration in minutes"
            v-model="lessonDuration"
          ></vs-input>
        </div>
        <vs-checkbox class="lesson__check-repeat" v-model="repeat"
          >Repeat lesson</vs-checkbox
        >
        <div class="lesson__repeat" v-if="repeat">
          <div class="repeat__option">
            <label for="">Repeat</label>
            <vs-input
              label="Every"
              v-model="interval"
              class="repeat-interval"
            ></vs-input>
            <vs-select label="Period" v-model="freq">
              <vs-select-item value="daily" text="day" />
              <vs-select-item value="weekly" text="week" />
              <vs-select-item value="monthly" text="month" />
            </vs-select>
          </div>
          <div class="repeat__option">
            <label for="">Until</label>
            <section>
              <vs-radio v-model="untilType" vs-name="untilType" vs-value="month"
                >End of {{ $moment(now).format('MMMM') }}</vs-radio
              >
              <vs-radio v-model="untilType" vs-name="untilType" vs-value="group"
                >End of group</vs-radio
              >
              <vs-radio v-model="untilType" vs-name="untilType" vs-value="year"
                >End of year</vs-radio
              >
              <vs-radio
                v-model="untilType"
                vs-name="untilType"
                vs-value="custom"
                >Custom date</vs-radio
              >
              <v-date-picker
                v-if="untilType == 'custom'"
                v-model="until"
                mode="date"
              />
            </section>
          </div>
          <div class="repeat__option">
            <label for="">Total count to create</label>
            <vs-input size="large" v-model="count"></vs-input>
          </div>
        </div>
      </div>
      <div class="inline-part right">
        <div
          class="lesson__tutor__fields"
          v-if="lessonType == 'tutor' || lessonType == 'testing'"
        >
          <div class="field">
            <vs-input
              size="large"
              label="Lesson name"
              v-model="lessonName"
            ></vs-input>
          </div>

          <div class="field">
            <label for="">Student</label>
            <v-select
              label="name"
              :options="studentList"
              v-model="student"
              :reduce="item => item.id"
              autocomplete="on"
              clearable
              placeholder="Select student"
              :multiple="isMultipleStudentToSelect"
            >
              <template #selected-option="{ name }">
                <vs-avatar icon="person" size="small"></vs-avatar>
                {{ name }}
              </template>
            </v-select>
          </div>
          <div class="field" v-if="lessonType == 'tutor'">
            <label for="">School Year</label>
            <v-select
              label="name"
              :options="years"
              v-model="selectedSchoolyearId"
              :reduce="item => item.id"
              autocomplete="on"
              clearable
              placeholder="Select schoolyear"
            >
              <template #selected-option="{ name }">
                {{ name }}
              </template>
            </v-select>
          </div>
        </div>

        <div class="field">
          <label for="">Teacher</label>

          <v-select
            label="nickname"
            :options="teachers"
            v-model="teacher"
            :reduce="item => item.id"
            autocomplete="on"
            clearable
            placeholder="Select teacher"
          >
            <template #option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
              ></vs-avatar
              >{{ nickname }}
            </template>
            <template #selected-option="{ nickname, options }">
              <vs-avatar
                :src="
                  options && options.avatar
                    ? `${proxy}/static/files/${options.avatar}`
                    : undefined
                "
              ></vs-avatar>
              {{ nickname }}
            </template>
          </v-select>
          <div v-if="!$v.teacher.required" class="error">Field is required</div>
        </div>
        <div v-if="lessonType == 'testing' || lessonType == 'group'">
          <div class="field other-teachers">
            <label for="">Sub teachers</label>
            <v-select
              label="nickname"
              :options="teachers"
              v-model="otherTeachers"
              :reduce="item => item.id"
              autocomplete="on"
              multiple
              clearable
              placeholder="Select teacher"
              :selectable="
                option =>
                  option.id != teacher &&
                  !otherTeachers.includes(option.id) &&
                  !assistants.includes(option.id)
              "
            >
              <template #option="{ nickname, options }">
                <vs-avatar
                  :src="
                    options && options.avatar
                      ? `${proxy}/static/files/${options.avatar}`
                      : undefined
                  "
                  size="small"
                ></vs-avatar
                >{{ nickname }}
              </template>
              <template #selected-option="{ nickname, options }">
                <vs-avatar
                  :src="
                    options && options.avatar
                      ? `${proxy}/static/files/${options.avatar}`
                      : undefined
                  "
                  size="small"
                ></vs-avatar>
                {{ nickname }}
              </template>
            </v-select>
          </div>
          <div class="field assistants">
            <label for="">Assistants</label>
            <v-select
              label="nickname"
              :options="teachers"
              v-model="assistants"
              :reduce="item => item.id"
              autocomplete="on"
              multiple
              clearable
              placeholder="Select teacher"
              :selectable="
                option =>
                  option.id != teacher &&
                  !otherTeachers.includes(option.id) &&
                  !assistants.includes(option.id)
              "
            >
              <template #option="{ nickname, options }">
                <vs-avatar
                  :src="
                    options && options.avatar
                      ? `${proxy}/static/files/${options.avatar}`
                      : undefined
                  "
                ></vs-avatar
                >{{ nickname }}
              </template>
              <template #selected-option="{ nickname, options }">
                <vs-avatar
                  :src="
                    options && options.avatar
                      ? `${proxy}/static/files/${options.avatar}`
                      : undefined
                  "
                ></vs-avatar>
                {{ nickname }}
              </template>
            </v-select>
          </div>
        </div>
        <div class="field">
          <label for="">Room</label>
          <v-select
            label="name"
            :options="rooms"
            v-model="room"
            :reduce="item => item.id"
            autocomplete="on"
            clearable
            placeholder="Select room"
            :class="{ error: !$v.room.required }"
          >
            <template #option="{ name, icon }">
              <vs-icon :icon="icon"></vs-icon>
              {{ name }}
            </template>
            <template #selected-option="{ name, icon }">
              <vs-icon :icon="icon"></vs-icon>
              {{ name }}
            </template>
          </v-select>
          <div v-if="!$v.room.required" class="error">Field is required</div>
        </div>
        <div class="field" v-if="lessonType != 'testing'">
          <vs-input size="large" label="Price" v-model="price"></vs-input>
        </div>
        <div class="field">
          <label for="online">Lesson is online</label>
          <vs-switch id="online" v-model="is_online">
            <span slot="on">Online</span>
            <span slot="off">Offline</span>
          </vs-switch>
        </div>
      </div>
    </div>
    <div class="actions">
      <vs-button
        class="save-button"
        icon="save"
        @click="save"
        :disabled="submitDisabled"
        >Save</vs-button
      >
      <vs-button
        class="cancel-button"
        icon="close"
        color="#f1f1f1"
        @click="cancelHandler"
        :disabled="submitDisabled"
        >Cancel</vs-button
      >
    </div>
    <!-- <vs-textarea label="comment" v-model="comment"></vs-textarea> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import StudentStatuses from '@/components/common/statuses/studentStatuses'

export default {
  name: 'GeneralLessonForm',
  props: {
    lessonType: {
      type: String,
      default: 'tutor',
    },
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    lessonDuration: {
      type: Number,
      default: 60,
    },
    groupLesson: {},
    schoolyear_id: {
      type: Number,
    },
    groupFinish: {
      type: Date,
    },
    // -- TODO: comment
    lessonId: Number,
    submitDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vSelect,
  },
  data() {
    const now = new Date()
    let data = {
      proxy: process.env.VUE_APP_PROXY,
      date: this.startDate,
      lessonName: '', // заполняется пользователем для инд.занятий
      name: '',
      student: [],
      teacher: '',
      room: '',
      id: this.lessonId,
      price: null,
      repeat: false,
      interval: 1,
      freq: 'weekly',
      until: now,
      untilType: 'year',
      count: null,
      index: null,
      now,
      selectedSchoolyearId: null,
      isMultipleStudentToSelect: this.lessonType == 'tutor' ? false : true,
      otherTeachers: [],
      assistants: [],
      is_online: false,
    }
    if (this.groupLesson) {
      let {
        id,
        student,
        teacher,
        room,
        date,
        price,
        until,
        freq,
        count,
        interval,
        repeat,
        index,
        untilType,
        is_online,
        other_teachers,
        assistants,
      } = this.groupLesson

      date = !date ? this.startDate : (date *= 1000)
      freq = freq || data.freq
      until = until ? new Date(until * 1000) : data.until
      untilType = untilType || data.untilType
      count = count || data.count
      interval = interval || data.interval
      let selectedSchoolyearId = this.groupLesson.schoolyear_id
        ? this.groupLesson.schoolyear_id
        : this.currentYear
        ? this.currentYear.id
        : this.schoolyear_id

      Object.assign(data, {
        date: new Date(date),
        until,
        id,
        student,
        teacher,
        room,
        price,
        freq,
        count,
        interval,
        repeat: repeat || false,
        index,
        untilType,
        selectedSchoolyearId,
        is_online,
        otherTeachers: other_teachers || [],
        assistants: assistants || [],
      })
    }

    return data
  },
  computed: {
    ...mapState({
      teachers: state => state.teachers.teachers,
      rooms: state => state.rooms.rooms,
      students: state => state.students.students,
      years: state => state.years.years,
      currentYear: state => state.years.currentSchoolYear,
    }),
    studentList: function() {
      if (this.lessonType == 'testing') {
        return this.students.filter(s => s.status == StudentStatuses.testing)
      }
      return this.students
    },
  },
  validations: {
    teacher: {
      required,
    },
    room: {
      required,
    },
  },
  methods: {
    cancelHandler: function() {
      this.$emit('closeHandler')
    },
    save: function() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      let {
        id,
        date,
        repeat,
        count,
        interval,
        untilType,
        until,
        teacher,
        room,
        student,
        lessonType,
        index,
        name,
        lessonName,
        freq,
        price,
        lessonDuration,
        selectedSchoolyearId,
        otherTeachers,
        assistants,
        is_online,
      } = this

      switch (untilType) {
        case 'month':
          until = this.$moment()
            .endOf('month')
            .unix()
          break
        case 'group':
          until = this.groupFinish
            ? this.groupFinish.getTime() / 1000
            : this.currentYear.date_end_unix
          break
        case 'year':
          // until = null;
          until = this.currentYear.date_end_unix
          break
        case 'custom':
          until = this.until.getTime() / 1000
      }
      const lesson = {
        id,
        lessonName,
        date: date.getTime() / 1000,
        duration: lessonDuration,
        repeat,
        count: parseInt(count, 10),
        interval: parseInt(interval, 10),
        teacher,
        room,
        until,
        freq,
        price,
        untilType,
        other_teachers: otherTeachers,
        assistants,
        is_online,
      }

      if (lessonType == 'tutor' || lessonType == 'testing') {
        lesson.lesson_type = lessonType
        lesson.student = student
        lesson.name = name
        lesson.schoolyear_id =
          selectedSchoolyearId || this.currentYear.id || this.schoolyear_id
      }
      if (typeof index !== 'undefined') lesson.index = index
      // -- добавляем данные для совместимости
      lesson.day = date.getDay()
      lesson.name = this.$moment(date).format('ddd')
      lesson.time = this.$moment(date).format('HH:mm')
      // --
      this.$emit('saveLesson', lesson)
    },
  },
  created: async function() {
    if (!this.selectedSchoolyearId && this.currentYear.id) {
      this.selectedSchoolyearId = this.currentYear.id
    }
    if (!this.students.length) {
      await this.$store.dispatch('students/getStudentsAction')
    }
  },
}
</script>

<style lang="scss">
.popup-form {
  .vs-input {
    width: 100%;
    margin-bottom: 25px;
  }
}
.lesson-form {
  position: relative;
  .time-picker {
    margin: 15px 0;
    width: 100%;
  }
  .lesson__check-repeat {
    margin-top: 15px;
  }
  .lesson__repeat {
    padding: 15px;
    background-color: #eee;
    border-radius: 10px;
    .repeat__option {
      display: flex;
      flex-flow: row nowrap;
      & > label {
        width: 70px;
      }
      & > * {
        margin: 5px;
      }
      .repeat-interval {
        max-width: 5em;
        .vs-input--input.normal {
          padding: 0.6rem;
        }
      }
      .con-vs-radio {
        justify-content: start;
        margin-bottom: 10px;
      }
    }
  }
  .actions {
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 15px;
    .vs-button {
      float: none;
      margin-right: 10px;
    }
    .cancel-button {
      color: var(--font-color);
      z-index: 101;
    }
  }
}
</style>
