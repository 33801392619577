<template>
  <div class="groups__form">
    <div
      class="groups__form-content form-content-two-parts"
      ref="parentSidebar"
      id="parentx"
    >
      <div class="inline-part left">
        <div class="field">
          <vs-input
            size="large"
            label-placeholder="Name"
            v-model="name"
          ></vs-input>
        </div>
        <div class="field">
          <compact-picker label="Group color" v-model="color" />
        </div>
        <div class="field">
          <vs-select label="Group type" v-model="group_type_id">
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in groupTypes"
            />
          </vs-select>
        </div>

        <div class="message" v-if="selectedGroupType.with_fixed_rate">
          <vs-alert active="true">
            Знаятия для данного типа группы будут добавлены без учеников. Перед
            расчетом группы нужно будет сначала добавить учеников в каждое
            занятие.
          </vs-alert>
        </div>
        <div class="field">
          <vs-select size="large" label="School Year" v-model="schoolyear_id">
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in years"
            />
          </vs-select>
        </div>

        <hr />
        <div class="field">
          <label for="">Students in Group</label>

          <v-select
            label="name"
            :options="filteredStudents"
            v-model="students"
            :reduce="(item) => item.id"
            :closeOnSelect="false"
            clearable
            multiple
            placeholder="Select students"
            :selectable="studentSelectableHandler"
          >
            <template #option="{ name }">
              <vs-avatar icon="person"></vs-avatar>
              {{ name }}
            </template>
            <template #selected-option="{ name }">
              <vs-avatar icon="person" size="20px"></vs-avatar>
              {{ name }}
            </template>
            <template #list-header>
              <div class="select-header">
                <vs-chip
                  :color="
                    statusID == studentStatuses['allAccepted']
                      ? 'primary'
                      : null
                  "
                >
                  <div @click="filterStudentsList('allAccepted')">All</div>
                </vs-chip>
                <vs-chip
                  :color="
                    statusID == studentStatuses['queue'] ? 'primary' : null
                  "
                >
                  <div @click="filterStudentsList('queue')">Queue</div>
                </vs-chip>
                <vs-chip
                  :color="
                    statusID == studentStatuses['testing'] ? 'primary' : null
                  "
                >
                  <div @click="filterStudentsList('testing')">Testing</div>
                </vs-chip>
              </div>
            </template>
          </v-select>
        </div>
        <hr />
        <div class="inline-fields">
          <div class="field">
            <label for="">Start date</label>
            <v-date-picker v-model="start_from" mode="date" ref="calendar">
              <template v-slot="{ inputValue, inputEvents }">
                <div>
                  <vs-input
                    class="calendar-input"
                    :value="`${inputValue}`"
                    v-on="inputEvents"
                    size="large"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <label for="">End date</label>
            <v-date-picker v-model="end_to" mode="date" ref="calendar">
              <template v-slot="{ inputValue, inputEvents }">
                <div>
                  <vs-input
                    class="calendar-input"
                    :value="`${inputValue}`"
                    v-on="inputEvents"
                    size="large"
                    :disabled="endFieldState"
                  />
                </div>
              </template>
            </v-date-picker>
            <vs-checkbox v-model="endFieldState">
              To end of schoolyear
            </vs-checkbox>
          </div>
        </div>
        <div class="field">
          <vs-checkbox v-model="in_holidays">
            Группа работает в праздники
          </vs-checkbox>
        </div>
      </div>

      <div class="inline-part right">
        <h3>
          Lessons ({{ lessons.length }} /
          {{ selectedGroupType.lessons_count || "-" }} per week)
        </h3>
        <div v-if="group_type_id && schoolyear_id">
          <div class="lessons">
            <div class="lesson" v-for="(l, index) in lessons" :key="index">
              <groups-form-lesson-item
                :index="index"
                :lesson="l"
                :schoolyear_id="schoolyear_id"
                @editGroupLesson="editGroupLessonHandler"
                @deleteGroupLesson="deleteGroupLessonHandler"
              />
            </div>
          </div>
        </div>
        <div v-else>Select group type and schoolyear</div>
        <div v-if="lessonUpdateFrom">
          <vs-alert
            active="true"
            color="primary"
            title="Обновить занятия начиная с даты:"
            class="lesson__update"
          >
            <v-date-picker
              v-model="lessonUpdateFrom"
              mode="date"
              ref="calendar"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div>
                  <vs-input
                    class="calendar-input"
                    :value="`${inputValue}`"
                    v-on="inputEvents"
                    size="large"
                  />
                  <span
                    @click="_setLessonUpdateFrom(start_from)"
                    class="lesson__update__correct"
                  >
                    From group start date
                  </span>
                </div>
              </template>
            </v-date-picker>
          </vs-alert>
        </div>
        <vs-button
          icon="add"
          type="flat"
          @click="lessonPopupActive = true"
          v-if="group_type_id && schoolyear_id"
        >
          Add lesson
        </vs-button>
      </div>
    </div>

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveGroup">Save</vs-button>
    </div>
    <div v-if="lessonPopupActive">
      <vs-popup
        class="form-popup popup__lessons"
        title="Lesson Settings"
        spacer
        :active.sync="lessonPopupActive"
        @close="closeLessonFormHandler"
      >
        <lesson-form
          v-if="lessonPopupActive"
          lessonType="group"
          :lessonDuration="lessonDuration"
          :groupLesson="lessonToEdit"
          :schoolyear_id="schoolyear_id"
          :groupFinish="end_to"
          @closeHandler="closeLessonFormHandler"
          @saveLesson="saveLessonHandler"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
// import VueTimepicker from "vue2-timepicker";
import { Compact } from "vue-color";
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import GroupsFormLessonItem from "./groupsFormLessonItem.vue";
import LessonForm from "../lessons/generalLessonForm.vue";
import StudentStatuses from "@/components/common/statuses/studentStatuses";

export default {
  name: "StudentForm",
  props: ["group"],
  data() {
    let data = {
      id: null,
      name: "",
      color: "#ffffff",
      group_type_id: null,
      schoolyear_id: null,
      lessons: [],
      students: [],
      start_from: new Date(),
      lessonPopupActive: false,
      lessonToEdit: {},
      defaultLessonDuration: 60,
      lessonUpdateFrom: null,
      endFieldState: false,
      in_holidays: false,
      end_to: null,
      statusID: 4,
      studentStatuses: StudentStatuses,
    };
    if (this.group.id) {
      let endFieldState = false;

      let {
        id,
        name,
        color,
        lessons_time,
        students,
        group_type,
        schoolyear,
        start_from,
        end_to,
        in_holidays,
        created,
      } = this.group;

      students = students.filter((s) => s.is_active).map((s) => s.id);
      const group_type_id = group_type.id;
      const schoolyear_id = schoolyear.id;

      if (end_to) {
        end_to = new Date(end_to * 1000);
      } else {
        endFieldState = true;
      }
      lessons_time = lessons_time || [];

      Object.assign(data, {
        start_from: new Date((start_from || created) * 1000),
        lessons: lessons_time
          .map((l, index) => {
            l.index = index;
            return l;
          })
          .sort((a, b) => a.date - b.date),
        id,
        name,
        color,
        students,
        group_type_id,
        schoolyear_id,
        end_to,
        in_holidays,
        endFieldState,
      });
    }
    return data;
  },
  components: {
    "compact-picker": Compact,
    vSelect,
    GroupsFormLessonItem,
    LessonForm,
  },
  computed: {
    ...mapState({
      groups: (state) => state.groups.groups,
      // group: state => state.groups.singleGroup,
      groupTypes: (state) => state.groups.groupTypes,
      years: (state) => state.years.years,
      teachersList: (state) => state.teachers.teachers,
      rooms: (state) => state.rooms.rooms,
      studentsList: (state) => state.students.students,
    }),
    lessonDuration: function () {
      return this.group_type_id
        ? this.groupTypes.find((t) => t.id == this.group_type_id).duration
        : this.defaultLessonDuration;
    },
    selectedGroupType: function () {
      if (this.group_type_id && this.groupTypes.length) {
        return this.groupTypes.find((t) => t.id == this.group_type_id);
      }
      return {};
    },
    filteredStudents: function () {
      return this.studentsList.map((s) => {
        const { name, id } = s;
        let visible = false;
        if (this.statusID == 4 && !s.status) visible = true;
        if (s.status == this.statusID) visible = true;
        return {
          id,
          name,
          visible,
        };
      });
    },
  },
  methods: {
    saveGroup: function () {
      const {
        id,
        name,
        color,
        lessons,
        group_type_id,
        schoolyear_id,
        students,
        start_from,
        end_to,
        in_holidays,
        lessonUpdateFrom,
      } = this;
      let data = {
        name,
        group_type_id,
        schoolyear_id,
        color: color.hex ? color.hex : color,
        start_from: start_from.getTime() / 1000,
        students: students,
        in_holidays,
      };
      if (lessonUpdateFrom) {
        data = {
          ...data,
          update_from: lessonUpdateFrom.getTime() / 1000,
          lessons_time: lessons,
        };
      }
      if (end_to != this.group.end_to) {
        // если меняем дату завершения группы, то отправляем этот параметр,
        // при этом занятия пересчитаются
        data.end_to = end_to ? end_to.getTime() / 1000 : null;
      }

      if (id) data.id = id;

      this.$store
        .dispatch("groups/saveGroupsAction", {
          groups: data,
        })
        .then(() => {
          this.$emit("closePopup");
        });
    },
    editGroupLessonHandler: function (index, oldDate) {
      const lesson = this.lessons[index];
      if (!lesson) {
        this.$vs.notify({
          title: "Error",
          text: `Lesson not found`,
          color: "danger",
          position: "top-right",
        });
      }

      if (!lesson.date) {
        // считаем, что старая настройка занятий, поэтому всегда повторяем
        if (!lesson.freq) lesson.freq = "weekly";
        lesson.repeat = true;
        lesson.interval = 1;
        lesson.date = oldDate;
      }
      lesson.index = index;

      this.lessonToEdit = lesson;
      this.lessonPopupActive = true;
    },
    deleteGroupLessonHandler: function (index) {
      this.lessons.splice(index, 1);
      this._setLessonUpdateFrom();
    },
    closeLessonFormHandler: function () {
      this.lessonToEdit = {};
      this.lessonPopupActive = false;
    },
    saveLessonHandler: function (lesson) {
      if (typeof lesson.index !== "undefined") {
        this.lessons.splice(lesson.index, 1, lesson);
      } else {
        lesson.index = this.lessons.length - 1;
        this.lessons.push(lesson);
      }
      this.lessonToEdit = {};
      this.lessonPopupActive = false;
      this._setLessonUpdateFrom();
    },
    _setLessonUpdateFrom: function (date) {
      this.lessonUpdateFrom = date || new Date();
    },
    studentSelectableHandler: function (option) {
      if (!option.visible) return false;
      if (this.students.indexOf(option.id) >= 0) return false;
      return true;
    },

    filterStudentsList: function (status) {
      let statusID = this.studentStatuses[status];
      if (!statusID) {
        console.warn("error in status id");
        return;
      }
      this.statusID = statusID;
    },
  },
  watch: {
    endFieldState: function (newV) {
      if (newV) {
        this.end_to = null;
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch("groups/getGroupTypesAction").then(() => {});
    this.$store.dispatch("years/getYearsAction").then(() => {});
    this.$store.dispatch("teachers/getTeachersAction").then(() => {});
    this.$store.dispatch("rooms/getRoomsAction").then(() => {});
    this.$store.dispatch("students/getStudentsAction").then(() => {});
  },
};
</script>

<style lang="scss">
.groups__form {
  .field {
    .vs-input {
      width: 100%;
      margin-bottom: 25px;
    }
  }
  .con-select {
    width: 100%;
  }
  .con-vs-alert {
    overflow: visible;
    .vs-alert {
      overflow: visible;
      & > div {
        margin: 7px 0;
      }
    }
  }
  hr {
    margin: 25px 0;
    color: #eee;
  }
  .day {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    margin: 10px;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    box-shadow: 0 0 3px 1px #ccc;
    .day__name {
      vertical-align: top;
      display: inline-block;
      margin: 0 7px;
      padding-bottom: 4px;
    }
    .day__settings {
      display: flex;
      flex-flow: row nowrap;
      vertical-align: middle;
      align-items: flex-end;
      & > * {
        margin: 0 10px;
        align-items: flex-end;
      }
      .day__room,
      .day__teacher {
        width: 100px;
      }
    }
  }
  .vue__time-picker input.has-custom-icon {
    border-radius: 5px;
    border-color: #d7d7d7;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .lesson__update__correct {
    display: inline-block;
    cursor: pointer;
    padding: 2px;
    margin: 5px;
    border-bottom: 1px dashed;
    &:hover {
      color: var(--font-color);
    }
  }
}
.inline-fields {
  display: flex;
  flex-flow: row nowrap;
  .field {
    flex: 1;
    margin: 0 20px 0;
  }
}
.select-header {
  padding: 0.4rem 0.6rem 0.3rem 1.6rem;
  background-color: #f1f1f1;
  display: flex;
  flex-flow: row nowrap;
}
</style>