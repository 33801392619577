<template>
  <div class="groups__list">
    <vs-table
      v-model="selected"
      search
      :data="groups"
      @dblSelection="doubleClickHandler"
    >
      <template slot="header">
        <h3>Groups ({{ groups.length }})</h3>
      </template>
      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="name">School year</vs-th>
        <vs-th sort-key="name">Type</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].name">
            <vs-icon icon="portrait" v-if="tr.is_tutor" />

            <vs-icon icon="people" v-else />
            <span
              :style="{ backgroundColor: data[indextr].color }"
              class="group-color"
            ></span>
            {{ data[indextr].name }}
          </vs-td>
          <vs-td :data="data[indextr].schoolyear">{{
            data[indextr].schoolyear ? data[indextr].schoolyear.name : ''
          }}</vs-td>
          <vs-td :data="data[indextr].group_type">
            <div v-if="data[indextr].group_type">
              {{ data[indextr].group_type.name }}
            </div>
          </vs-td>

          <vs-td :data="data[indextr].id">
            <vs-button
              v-if="$can('manage', 'group')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="edit"
              type="flat"
              @click="editGrouptHandle(data[indextr].id)"
            ></vs-button>
            <vs-button
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="visibility"
              type="flat"
              @click="viewGroupHandler(data[indextr].id)"
            ></vs-button>
            <vs-button
              v-if="$can('manage', 'group')"
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="delete"
              type="flat"
              @click="deleteGroup(data[indextr].id)"
            ></vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['groups'],
  data() {
    return {
      selected: [],
      selectedId: null,
    }
  },
  computed: {
    ...mapState({
      // groups: (state) => state.groups.groups,
      years: state => state.years.years,
      currentYear: state => state.years.currentSchoolYear,
      groupTypes: state => state.groups.groupTypes,
    }),
  },
  watch: {},
  methods: {
    deleteGroup: function(id) {
      this.selectedId = id
      this.openAlert()
    },
    editGrouptHandle: function(id) {
      this.$emit('editGroup', id)
    },
    doubleClickHandler: function(tr) {
      this.$router.push({ name: 'Group', params: { group_id: tr.id } })
    },
    viewGroupHandler: function(id) {
      this.$router.push({ name: 'Group', params: { group_id: id } })
    },
    openAlert: function() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Delete group',
        text: 'Are you sure you want to delete the group?',
        accept: this.acceptAlert,
      })
    },
    acceptAlert: function() {
      this.$emit('deleteGroup', this.selectedId)
      this.selectedId = null
    },
    changeSchoolYearHandler: function() {
      this.$emit('changeSchoolYear', this.selectedYearId)
    },
  },
}
</script>

<style>
.group-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0 10px;
}
</style>
