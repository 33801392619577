<template>
  <div class="groups">
    <div class="groups__manage">
      <vs-button
        icon="add"
        type="flat"
        v-if="$can('manage', 'group')"
        @click="popupActive = true"
        >Add Group</vs-button
      >
      <vs-select
        v-model="selectedYearId"
        description-text="School year"
        class="schoolyear-select"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.name"
          v-for="(item, index) in years"
        />
      </vs-select>
    </div>
    <vs-tabs v-model="activeTab">
      <vs-tab :label="`Basic (${basicGroups.length || 0})`">
        <GroupsList
          @editGroup="editGroup($event)"
          @deleteGroup="deleteGroup($event)"
          :groups="basicGroups"
        />
      </vs-tab>
      <vs-tab :label="`Tutor (${tutorGroups.length || 0})`">
        <GroupsList
          @editGroup="editGroup($event)"
          @deleteGroup="deleteGroup($event)"
          :groups="tutorGroups"
      /></vs-tab>
    </vs-tabs>

    <vs-popup
      class="groups__popup"
      classContent="groups__popup-content"
      title="Group"
      fullscreen
      :active.sync="popupActive"
      @close="closePopupHandle"
    >
      <GroupsForm
        v-if="popupActive"
        :group="selectedGroup"
        @closePopup="closePopup"
      />
    </vs-popup>
  </div>
</template>

<script>
import GroupsList from '@/components/groups/groupsList.vue'
import GroupsForm from '@/components/groups/groupsForm.vue'
import { mapState } from 'vuex'

export default {
  title: 'SE-Groups',
  data() {
    return {
      popupActive: false,
      selectedGroup: {},
      yearId: null,
      activeTab: 0,
      selectedYearId: null,
    }
  },
  computed: {
    ...mapState({
      groups: state => state.groups.groups,
      singleGroup: state => state.groups.singleGroup,
      years: state => state.years.years,
      currentSchoolYear: state => state.years.currentSchoolYear,
    }),
    basicGroups: function() {
      return this.groups.filter(g => g.is_tutor != true)
    },
    tutorGroups: function() {
      return this.groups.filter(g => g.is_tutor == true)
    },
  },
  components: { GroupsList, GroupsForm },
  created() {
    this.$store.dispatch('years/getYearsAction').then(() => {
      this.yearId = this.currentSchoolYear.id
    })
  },
  watch: {
    selectedYearId: function(newV) {
      const data = {
        schoolyear_id: newV,
        exclude_students: 1,
      }
      this.$store.dispatch('groups/getGroupsAction', { data }).then(() => {})
    },
    currentSchoolYear: function(newV) {
      if (newV) this.selectedYearId = newV.id
    },
  },
  methods: {
    editGroup: function(id) {
      if (!id) {
        this.$vs.notify({
          title: 'Error',
          text: `Error open Group`,
          color: 'danger',
          position: 'top-right',
        })
        this.selectedRoom = {}
        return
      }
      //   this.selectedGroup = this.groups.find(s => s.id == id);
      this.$store.dispatch('groups/getSingleGroupAction', { id }).then(() => {
        this.selectedGroup = this.singleGroup
        this.popupActive = true
      })
    },
    deleteGroup: function(id) {
      this.$store
        .dispatch('groups/deleteGroupsAction', id)
        .then(() => {
          this.selectedGroup = {}
          this.popupActive = false
          this.$vs.notify({
            title: 'Removed',
            text: `Group removed`,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(r => {
          this.selectedGroup = {}
          this.popupActive = false
          this.$vs.notify({
            title: 'Error',
            text: r.message,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    closePopup: function() {
      this.popupActive = false
      this.selectedGroup = {}
    },
    closePopupHandle: function() {
      this.selectedGroup = {}
    },
  },
}
</script>

<style lang="scss">
.groups__list {
  clear: both;
  .con-select {
    margin: 0 15px;
    display: flex;
    flex-flow: row nowrap;
  }
}
.groups__manage {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  button {
    margin-bottom: 10px;
  }
  .schoolyear-select {
    margin-left: 1rem;
    padding: 0.5rem;
    background-color: var(--table-disabled-bcg);
    border-radius: 0.5rem;
  }
}
</style>
